// TODO: This is too similar to AdvanceArticle hero to have two style templates

import styled from 'styled-components'

import { DEVICE } from '../../../util/constants'
import { HeroH1, HeroP } from '../../styles/Utility.styles'
import { RegionContainer } from '../../styles/Utility.styles'

export const HeroTextRegion = styled.div`
  background-color: var(--content-background-light);
  margin-top: 26px;
`

export const HeroTextGrid = styled(RegionContainer)`
  justify-items: center;
  text-align: center;
`

export const HeroImageGrid = styled(RegionContainer)`
  justify-items: center;
`

export const HeroTitle = styled(HeroH1)`
  padding: 24px 0 20px;
  grid-column: 2 / 3;
  grid-row: 1;
`

export const HeroBody = styled(HeroP)`
  grid-column: 2 / 3;
  grid-row: 2;
  padding-bottom: 20px;

  @media ${DEVICE.tabletL} {
    padding-bottom: 46px;
  }
`

export const ImageBackground = styled.div`
  height: max-content;
  width: 100%;
  background: linear-gradient(
    to bottom,
    var(--content-background-light) 50%,
    white 50%
  );
`

export const HeroImage = styled.picture`
  grid-column: 2 / 3;
  margin-bottom: 64px;
`
